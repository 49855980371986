@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-roboto;
}

.amaranth-regular {
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.amaranth-bold {
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.amaranth-regular-italic {
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.amaranth-bold-italic {
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-style: italic;
}

/* Partners Page */

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.lexend-700 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.custom-dashed-line {
  display: block;
  width: 6px;
  height: 90px;
  background-image: linear-gradient(to bottom, #949494 40%, transparent 10%);
  background-position: center bottom;
  background-repeat: repeat-y;
  background-size: 2px 10px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.scrollbar-hide {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
